@import url("https://fonts.googleapis.com/css?family=Roboto:400,500");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

html {
  font-size: 62.5%;
}

* {
  box-sizing: border-box;
  font-family: "Roboto";
  // border: 1px solid black;
}

body {
  background: linear-gradient(to bottom, #39c, #fff);
  min-height: 100vh;
  font-family: "Roboto", sans-serif;
}

.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.app-container {
  max-width: 70%;
  border: 1px solid black;
  margin: 3rem auto;
  background-color: rgba(252, 252, 252, 0.966);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  padding: 2rem;
}

.add-todo-container {
  margin: 0 auto 2rem;
  width: 50%;
  text-align: center;
}

.flex-divide-containers {
  display: flex;
  justify-content: space-around;
  max-width: 70%;
  margin: auto;

  > div {
    width: 50%;

    > p {
      padding: 1rem;
      margin: 0;
    }

    > p:nth-child(odd) {
      background-color: #dddddd;
    }
  }

  > div:not(:last-child) {
    margin-right: 1rem;
  }
}

.todo-text-field {
  margin-bottom: 2rem;
}

h1 {
  font-size: 3rem;
}

.todo-category-header {
  // background-color: #39c;
  // border-radius: 4px;
  border-bottom: 2px solid black;
  margin: 0 auto 2rem;
  text-align: center;
  width: 100%;
  background-color: #39c;
  border-radius: 4px;
  color: #fff;
  font-weight: 300;
  font-size: 1.9rem;
  text-transform: uppercase;
  letter-spacing: 4px;
  padding: 1rem;
}

.test {
  font-size: 30rem;
  padding: 10rem;
}

.vertical-align-middle {
  vertical-align: middle;
}

.mark-completed {
  margin-left: 0.5rem;
  font-size: 1.5rem;
}

.check-icon {
  transition: all 0.2s;
}

.hover-test {
  display: inline-block;
  cursor: pointer;
  transition: all 0.2s;
  background-color: #e6e6e6;
  border-radius: 5px;
  padding: 0.5rem;
  margin-top: 1rem;

  &:hover {
    .check-icon {
      color: green;
    }
  }
}

.split {
  display: flex;
  justify-content: space-around;
  align-content: center;
}

.completed-badge {
  bottom: 0.5rem;
  right: 1rem;
  color: rgb(7, 136, 7);
  font-size: 2rem;
}

.completed-badge-hidden {
  display: none;
}

.completed-badge,
.trash-icon {
  outline: none;
  position: absolute;
  font-size: 2rem;
}

.trash-icon {
  color: rgb(194, 6, 6);
  top: 0.5rem;
  right: 1rem;
  transition: color 0.2s;
  cursor: pointer;

  &:hover {
    color: rgb(245, 0, 0);
  }
}
