@import url(https://fonts.googleapis.com/css?family=Roboto:400,500);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  font-family: "Roboto";
  font-size: 1.4rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

html {
  font-size: 62.5%; }

* {
  box-sizing: border-box;
  font-family: "Roboto"; }

body {
  background: -webkit-gradient(linear, left top, left bottom, from(#39c), to(#fff));
  background: -webkit-linear-gradient(top, #39c, #fff);
  background: linear-gradient(to bottom, #39c, #fff);
  min-height: 100vh;
  font-family: "Roboto", sans-serif; }

.App {
  text-align: center; }

.App-link {
  color: #61dafb; }

.app-container {
  max-width: 70%;
  border: 1px solid black;
  margin: 3rem auto;
  background-color: rgba(252, 252, 252, 0.966);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  padding: 2rem; }

.add-todo-container {
  margin: 0 auto 2rem;
  width: 50%;
  text-align: center; }

.flex-divide-containers {
  display: flex;
  justify-content: space-around;
  max-width: 70%;
  margin: auto; }
  .flex-divide-containers > div {
    width: 50%; }
    .flex-divide-containers > div > p {
      padding: 1rem;
      margin: 0; }
    .flex-divide-containers > div > p:nth-child(odd) {
      background-color: #dddddd; }
  .flex-divide-containers > div:not(:last-child) {
    margin-right: 1rem; }

.todo-text-field {
  margin-bottom: 2rem; }

h1 {
  font-size: 3rem; }

.todo-category-header {
  border-bottom: 2px solid black;
  margin: 0 auto 2rem;
  text-align: center;
  width: 100%;
  background-color: #39c;
  border-radius: 4px;
  color: #fff;
  font-weight: 300;
  font-size: 1.9rem;
  text-transform: uppercase;
  letter-spacing: 4px;
  padding: 1rem; }

.test {
  font-size: 30rem;
  padding: 10rem; }

.vertical-align-middle {
  vertical-align: middle; }

.mark-completed {
  margin-left: 0.5rem;
  font-size: 1.5rem; }

.check-icon {
  -webkit-transition: all 0.2s;
  transition: all 0.2s; }

.hover-test {
  display: inline-block;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  background-color: #e6e6e6;
  border-radius: 5px;
  padding: 0.5rem;
  margin-top: 1rem; }
  .hover-test:hover .check-icon {
    color: green; }

.split {
  display: flex;
  justify-content: space-around;
  align-content: center; }

.completed-badge {
  bottom: 0.5rem;
  right: 1rem;
  color: #078807;
  font-size: 2rem; }

.completed-badge-hidden {
  display: none; }

.completed-badge,
.trash-icon {
  outline: none;
  position: absolute;
  font-size: 2rem; }

.trash-icon {
  color: #c20606;
  top: 0.5rem;
  right: 1rem;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
  cursor: pointer; }
  .trash-icon:hover {
    color: #f50000; }

.create-account-heading,
.login-account-heading {
  margin: 0 0 2rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #dadada; }

.error-message {
  width: 100%;
  padding: 1rem 1.5rem;
  background-color: #b31414;
  border-radius: 3px;
  color: #fff;
  text-align: center; }

.reg-form-wrapper {
  max-width: 500px;
  width: 100%;
  margin: 5rem auto 0;
  border: 1px solid #b3b3b3;
  border-radius: 6px;
  padding: 5rem;
  background-color: #fff;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1), 0 2px 7px rgba(0, 0, 0, 0.19); }
  .reg-form-wrapper .input-field-label {
    margin: 2rem 0 0.7rem; }
  .reg-form-wrapper .firstName-lastName-container,
  .reg-form-wrapper .username-password-container {
    width: 100%;
    display: flex;
    justify-content: space-between; }
  .reg-form-wrapper input[type="text"],
  .reg-form-wrapper input[type="email"] {
    outline: none;
    border: none;
    border: 1px solid #b3b3b3;
    border-radius: 3px;
    padding: 1rem;
    font-size: 1.4rem;
    font-weight: 400;
    -webkit-transition: 0.2s border;
    transition: 0.2s border; }
    .reg-form-wrapper input[type="text"]:focus,
    .reg-form-wrapper input[type="email"]:focus {
      border-color: #39c;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.17); }
  .reg-form-wrapper .register-btn-container,
  .reg-form-wrapper .login-btn-container {
    margin-top: 5rem;
    text-align: center; }

.btn {
  padding: 1.3rem 1.5rem;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer; }
  .btn.register-btn, .btn.login-btn {
    outline: none;
    border: 1px solid #39c;
    background-color: #39c;
    color: #fff;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 1.5rem;
    min-width: 11rem; }
    .btn.register-btn:hover, .btn.login-btn:hover {
      background-color: transparent;
      color: #39c; }

.dark-icon {
  color: #000; }

